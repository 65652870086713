/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PRODUCT_CLASS_FOR_SPECIAL_ORDERS, PRODUCT_CLASS_FOR_WORK_ORDERS } from './cpa-utils';
import { PRODUCT_VERSION_SEARCH_FIELD_MAP } from './search-product-field-mapping';
export var DEFAULT_LANGUAGE_CODE = 'en-US';
export var SEARCH_PRODUCT_REQUEST_FIELDS = ['productVersion', 'prefix', 'productName'];
export var SEARCH_PRODUCT_PAGE_SIZE = 50;
export var SEARCH_HIERARCHY_PAGE_SIZE = 100;
export var REQUEST_TYPE_QUOTE = 'QUOTE';
export var REQUEST_TYPE_ORDER = 'ORDER';
export var REQUESTS_PAGE_SIZE = 100;
export var GSA_CHANNEL = 'GSA';
export var NA_CONTRACT_CHANNEL = 'NA Contract';
export var B2B_CHANNEL = 'B2B';
export var IMPLIED_FEATURE_TYPE = 'Implied';
export var CPA_DEFAULT_STEP_NO = '0';
export var CPA_DATE_FOR_VALIDATE = '2023-11-01';
export var SOURCE_PREFIX = {
    KNOLL: 'KNOLL_',
    CUSTOM: 'CUSTOM_',
    CSP: 'CSP_',
};
export var NON_FEATURE_DESCRIPTIONS = [
    'product_line',
    'product_function',
    'Deviation',
    'HM FSC Chain of Custody',
    'FSC Type',
    'Requestor',
    'Complexity',
    'Is Configured',
    'Standard Product Reference',
];
export var SEARCH_PRODUCT_SORT_VALUES = [
    {
        key: 'relevance',
        display: 'Relevance',
        direction: 'desc',
        directionDisplay: 'Desc',
    },
    {
        key: 'prefix',
        display: PRODUCT_VERSION_SEARCH_FIELD_MAP.prefix.displayName,
        direction: 'asc',
        directionDisplay: 'Asc',
    },
    {
        key: 'prefix',
        display: PRODUCT_VERSION_SEARCH_FIELD_MAP.prefix.displayName,
        direction: 'desc',
        directionDisplay: 'Desc',
    },
];
export var UI_WRITE_SCOPES = ['pub_ui_write', 'pub_ui_admin'];
export var CATALOG_SCOPES = ['pub_catalog_manager', 'pub_ui_admin'];
export var CPA_SCOPES = ['pub_ui_cpa_wo', 'pub_ui_cpa_sp', 'pub_ui_admin'];
export var ROYALTIY_SCOPES = ['pub_ui_royalty', 'pub_ui_admin'];
export var DATALAKE_SCOPES = ['pub_ui_admin'];
export var CATALOGS_SORT_VALUES = [
    {
        key: 'relevance',
        display: 'Relevance',
    },
    {
        key: 'catalogType',
        display: 'Category Type',
    },
    {
        key: 'fileNamePrefix',
        display: 'File Prefix',
    },
    {
        key: 'startDate',
        display: 'Start Date',
    },
    {
        key: 'catalogDesc',
        display: 'Description',
    },
    {
        key: 'channels.channelCode',
        display: 'Channel',
    },
    {
        key: 'leadTime',
        display: 'Lead Time',
    },
    {
        key: 'catalogNbr',
        display: 'Category Number',
    },
];
export var catTypeKey = {
    PRICEBOOK: 'priceBookPromotable',
    MARKETING_SIF: 'sifPromotable',
    CET_XML: 'cetPromotable',
};
export var catTypeTableDisplay = {
    PRICEBOOK: 'P',
    MARKETING_SIF: 'S',
    CET_XML: 'X',
};
export var fileTypesDisplayText = {
    cetxmlfile: 'CET XML File',
    optfile: 'Opt File',
    keyfile: 'Key File',
    topfile: 'Top File',
    bottomfile: 'Bottom File',
    pricebookfile: 'Price Book File',
    pipfile: 'Product Information Page',
    infocsvfile: 'Catalog Info',
    warningcsvfile: 'Catalog Warnings',
};
export var channelArray = ['Commercial', 'GSA', 'B2B'];
export var CURRENCY_ARRAY = {
    USD: 'US Dollars',
    CAD: 'Canadian Dollars',
};
export var CURRENCY_LIST = {
    USD: 'USD',
    CAD: 'CAD',
};
export var PRODUCTBUILD_TABS = {
    options: { key: 'options', display: 'Options' },
    productLevelRestrictions: { key: 'productLevelRestrictions', display: 'Product-Level Restrictions' },
    productLevelRoyalties: { key: 'productLevelRoyalties', display: 'Product-Level Royalties' },
};
export var PRODUCT_CLASS_ALLOWED = [PRODUCT_CLASS_FOR_SPECIAL_ORDERS, PRODUCT_CLASS_FOR_WORK_ORDERS];
