var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { CpaProductMetadata } from '@millerknoll/pub-api-schema';
import { useCpaProduct } from 'components/Cpa/CpaProduct/CpaProductContextProvider';
import { ActionType, InputFieldType } from 'models/Input/InputModels';
import { InputFieldsContainer } from 'components/Shared/InputFieldsContainer/InputFieldsContainer';
import { inputFieldsContainerHelper } from 'components/Shared/InputFieldsContainer/InputFieldsContainerHelper';
import { LoadingSpinner } from 'components/Shared/Loading/LoadingSpinner/LoadingSpinner';
import { useLookupSearch } from 'hooks/useLookupSearch';
import { LookupType } from 'services/api/search-service';
import { useProductValidate } from 'hooks/useProductValidate';
var PRODUCT_LINE_CODE_FIELD_NAME = 'productLineCode';
var STANDARD_PRODUCT_REF_FIELD_NAME = 'standardProductReference';
var DISCOUNT_CODE_FIELD_NAME = 'discountCode';
var PRODUCT_NAME = 'productName';
var CPN_FIELD_NAME = 'cpn';
export function CpaProductHeaderAdditionalInfoSpecials() {
    var _a;
    var _b;
    var cpaProductContext = useCpaProduct();
    /**
     * Declare state to hold field values of all columns , key as column heading
     */
    var _c = useState(buildFieldValues(undefined, undefined)), fieldValues = _c[0], setFieldValues = _c[1];
    var productLineValidate = useLookupSearch(LookupType.ProductLine, fieldValues[PRODUCT_LINE_CODE_FIELD_NAME].value);
    var discountCodeValidate = useLookupSearch(LookupType.DiscountCode, fieldValues[DISCOUNT_CODE_FIELD_NAME].value);
    var standardProductReferenceValidate = useProductValidate(fieldValues[STANDARD_PRODUCT_REF_FIELD_NAME].value, 'Standard Product Ref', undefined, ((_b = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProductMetadata) === null || _b === void 0 ? void 0 : _b.sourceSystem) === CpaProductMetadata.sourceSystem.CSP);
    /**
     * Holds Column Definition and Initial Values of the Column
     */
    var colDef = {
        rowGap: '4px',
        title: { displayText: 'Product Information', marginBottom: '16px' },
        showActions: true,
        preventReadOnly: true,
        fields: (_a = {},
            _a[CPN_FIELD_NAME] = {
                fieldName: 'Custom Product Number:',
                displaySeq: 0,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _a[STANDARD_PRODUCT_REF_FIELD_NAME] = {
                fieldName: 'Standard Product Number:',
                displaySeq: 1,
                hasLookup: false,
                input: {
                    type: InputFieldType.STRING,
                    validations: {
                        noSpaces: 'Invalid input. No spaces allowed.',
                    },
                },
            },
            _a[PRODUCT_LINE_CODE_FIELD_NAME] = {
                fieldName: 'Product Line (Code):',
                displaySeq: 2,
                hasLookup: true,
                input: {
                    type: InputFieldType.STRING,
                },
            },
            _a[DISCOUNT_CODE_FIELD_NAME] = {
                fieldName: 'Discount (Code):',
                displaySeq: 3,
                hasLookup: true,
                input: {
                    type: InputFieldType.STRING,
                },
            },
            _a[PRODUCT_NAME] = {
                fieldName: 'Description:',
                displaySeq: 4,
                hasLookup: false,
                input: {
                    type: InputFieldType.STRING,
                },
            },
            _a),
    };
    useEffect(function () {
        var _a;
        if (!((_a = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data) === null || _a === void 0 ? void 0 : _a.currentProduct) || !cpaProductContext.data.cpn) {
            return;
        }
        setFieldValues(buildFieldValues(cpaProductContext.data.currentProduct, cpaProductContext.data.cpn));
    }, [cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProduct, cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.cpn]);
    useEffect(function () {
        setFieldValues(function (cur) {
            cur[PRODUCT_LINE_CODE_FIELD_NAME].loading = productLineValidate.loading;
            cur[PRODUCT_LINE_CODE_FIELD_NAME].customErrorMsg = productLineValidate.errorMessage;
            return __assign({}, cur);
        });
    }, [productLineValidate.loading, productLineValidate.errorMessage]);
    useEffect(function () {
        setFieldValues(function (cur) {
            cur[DISCOUNT_CODE_FIELD_NAME].loading = discountCodeValidate.loading;
            cur[DISCOUNT_CODE_FIELD_NAME].customErrorMsg = discountCodeValidate.errorMessage;
            return __assign({}, cur);
        });
    }, [discountCodeValidate.loading, discountCodeValidate.errorMessage]);
    useEffect(function () {
        setFieldValues(function (cur) {
            cur[STANDARD_PRODUCT_REF_FIELD_NAME].loading = standardProductReferenceValidate.loading;
            cur[STANDARD_PRODUCT_REF_FIELD_NAME].customErrorMsg = standardProductReferenceValidate.errorMessage;
            return __assign({}, cur);
        });
    }, [standardProductReferenceValidate.loading, standardProductReferenceValidate.errorMessage]);
    /**
     * Called on Confirmation or Cancellation. On Confirm, updates Columns Definition with updated field values. Rests field values to Initial values from Column Definition
     */
    var handleAction = function (actionType) {
        if (!(cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProduct)) {
            return;
        }
        if (actionType === ActionType.CONFIRM) {
            postConfirmationUpdate();
        }
        else if (actionType === ActionType.REVERT) {
            setFieldValues(buildFieldValues(cpaProductContext.data.currentProduct, cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.cpn));
        }
    };
    var postConfirmationUpdate = function () {
        updateCurrentProductCustomFields(colDef);
        cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.dispatch({
            type: 'UPDATE_CURRENT_PRODUCT',
            payload: __assign({}, cpaProductContext.data.currentProduct),
        });
    };
    function updateCurrentProductCustomFields(def) {
        if (!cpaProductContext || !cpaProductContext.data.currentProduct) {
            return;
        }
        Object.keys(fieldValues).forEach(function (propertyName) {
            if (cpaProductContext.data.currentProduct) {
                var currVal = fieldValues[propertyName].value;
                var _a = currVal.split(' '), code = _a[0], descriptionParts = _a.slice(1);
                var description = descriptionParts.join(' ');
                if (propertyName === PRODUCT_LINE_CODE_FIELD_NAME) {
                    cpaProductContext.data.currentProduct.productLineCode = code;
                    cpaProductContext.data.currentProduct.productLineDesc = description;
                }
                if (propertyName === DISCOUNT_CODE_FIELD_NAME) {
                    cpaProductContext.data.currentProduct.discountCode = code;
                    cpaProductContext.data.currentProduct.discountDesc = description;
                }
                if (propertyName === PRODUCT_NAME) {
                    cpaProductContext.data.currentProduct.productName = currVal;
                }
                if (propertyName === STANDARD_PRODUCT_REF_FIELD_NAME) {
                    cpaProductContext.data.currentProduct.standardProductReference = currVal;
                }
            }
        });
    }
    return colDef ? (_jsx(InputFieldsContainer, { colDef: colDef, handleAction: handleAction, fieldValues: fieldValues, setFieldValue: function (property, value) {
            setFieldValues(inputFieldsContainerHelper.getUpdatedFieldValues(fieldValues, property, value));
        }, labelFieldNameWidth: "160px", lineHeight: "40px" })) : (_jsx(LoadingSpinner, { indicatorSize: 10 }));
}
var buildFieldValues = function (cpaProduct, cpn) {
    var _a;
    return (_a = {},
        _a[PRODUCT_NAME] = {
            value: cpaProduct === null || cpaProduct === void 0 ? void 0 : cpaProduct.productName,
        },
        _a[DISCOUNT_CODE_FIELD_NAME] = {
            value: cpaProduct === null || cpaProduct === void 0 ? void 0 : cpaProduct.discountCode,
        },
        _a[PRODUCT_LINE_CODE_FIELD_NAME] = {
            value: cpaProduct === null || cpaProduct === void 0 ? void 0 : cpaProduct.productLineCode,
        },
        _a[STANDARD_PRODUCT_REF_FIELD_NAME] = {
            value: cpaProduct === null || cpaProduct === void 0 ? void 0 : cpaProduct.standardProductReference,
        },
        _a[CPN_FIELD_NAME] = {
            value: cpn,
        },
        _a);
};
